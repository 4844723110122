<script>
export default
    {
        name: 'HelexiumInput',
        props:
        {
            type:
            {
                type: String,
                default: "text"
            },
            label:
            {
                type: String,
                default: ""
            },
            name:
            {
                type: String,
                default: ""
            },
            placeholder:
            {
                type: String,
                default: ""
            },
            help:
            {
                type: String,
                default: ""
            },
            validations:
            {
                type: String,
                default: ""
            },
            options:
            {
                type: Array,
                default: () => []
            },
            state: {
                type: String,
                default: ""
            },
            errorMessage: {
                type: String,
                default: ""
            },
            initialValue: {
                default: ""
            },
        },
        mounted() {
            // let valueValidations = this.validations ? 1 : 0 // ? importante

            if (this.initialValue) {

                //Codigo redundante y mejorable
                if (this.type == 'select') {
                    if (typeof (this.options[0]) == 'string') {
                        let aux = this.options.find(option => option == this.initialValue)
                        this.fakeInputValue = aux
                        this.inputValue = aux
                    }
                    else {
                        const aux = this.options.find(option => option.value == this.initialValue)
                        this.fakeInputValue = aux.label
                        this.inputValue = aux.value
                    }
                } else if (this.type == 'date') {
                    this.inputValue = new Date(this.initialValue).toISOString().substring(0, 10)
                }
                else {
                    this.inputValue = this.initialValue
                }
            }

            this.doValidations()
            this.$emit('singleValidation',
                {
                    name: this.name,
                    validation: this.validations ? this.errorMessages.length : 0
                })

        },
        data() {
            return {
                inputValue: null,
                fakeInputValue: null,
                errorMessages: [],
                indexOption: -1,
            }
        },
        methods:
        {
            emitValue() {
                this.doValidations()
                let valueValidations = this.validations ? this.errorMessages.length : 0
                this.$emit('singleValidation', { name: this.name, validation: valueValidations, value: this.inputValue })

                if (this.type == "date") {
                    const date = this.inputValue + "T00:00:00"
                    this.$emit('input', date)
                    return
                }

                this.$emit('input', this.inputValue)

            },
            updateOption(option) {
                if (typeof (option) == 'string') {
                    this.inputValue = option
                    this.fakeInputValue = option
                }
                else {
                    this.fakeInputValue = option.label
                    this.inputValue = option.value
                }

                this.emitValue()
            },
            doValidations() {
                this.errorMessages = []

                //"required"
                if (this.validations.includes("required") && !this.inputValue && this.inputValue != 0 && this.inputValue != false) {
                    this.errorMessages.unshift("Este campo es requerido")
                }

                //"email"
                if (this.validations.includes("email") && this.inputValue) {
                    let emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/
                    if (!emailRegex.test(this.inputValue)) {
                        this.errorMessages.unshift("El email no es válido")
                    }
                }

                if (this.validations.includes("length") && this.inputValue) {
                    let lengthRegex = /length:\d+,\d+|length:\d+/
                    let length = this.validations.match(lengthRegex)[0].split(":")[1].split(",")
                    if (length.length == 1) {
                        if (this.inputValue.length < length[0]) {
                            this.errorMessages.unshift(`Este campo debe tener al menos ${length[0]} caracteres`)
                        }
                    }
                    else {
                        if (this.inputValue.length < length[0] || this.inputValue.length > length[1]) {
                            this.errorMessages.unshift(`Este campo debe tener entre ${length[0]} y ${length[1]} caracteres`)
                        }
                    }
                }

            },
            selectController(ev) {
                if (ev.key == 'ArrowUp' || ev.key == 'ArrowDown' ||
                    ev.key.toLowerCase() == 'w' || ev.key.toLowerCase() == 's'
                ) {
                    if (ev.key == 'ArrowDown' || ev.key.toLowerCase() == 's') {
                        this.indexOption++
                        if (this.indexOption >= this.options.length) this.indexOption = 0
                    }
                    else {
                        this.indexOption--
                        if (this.indexOption < 0) this.indexOption = this.options.length - 1
                    }

                    if (typeof (this.options[0]) == 'string') {
                        this.fakeInputValue = this.options[this.indexOption]
                        this.inputValue = this.options[this.indexOption]
                    }
                    else {
                        this.fakeInputValue = this.options[this.indexOption].label
                        this.inputValue = this.options[this.indexOption].value
                    }

                    this.emitValue()
                }
            }
        }

    }
</script>

<template>
    <div class="outer">
        <label :for="name" class="text-light">{{ label }}</label>
        <div class="inner">


            <input v-if="type === 'text' || type === 'number' || type === 'date' || type === 'time' || type === 'password'"
                @input="emitValue" :disabled="state == 'disabled'" :id="name" :type="type" class="input"
                v-model="inputValue" :placeholder="placeholder" :class="[state]">


            <div v-else-if="type == 'select'" class="select" :class="[state]">
                <input class="input inputSelect" type="text" :id="name" :value="fakeInputValue" onkeypress="return false"
                    @keydown="selectController" :disabled="state == 'disabled'">
                <div class="options">
                    <div
                    v-for="(option, idx) in options" :key="name + idx"
                    @click="updateOption(option)"
                    class="option" :class="{ 'active' : idx == indexOption}">
                        <template v-if="typeof (option) == 'string'">
                            {{ option }}
                        </template>
                        <template v-else-if="typeof (option) == 'object'">
                            {{ option.label || option.tipo }}
                        </template>
                    </div>
                </div>
            </div>



            <!-- correspondiente a text area con <overflow-y hidden, resize: none, y resizeauto </overflow->-->

            <!-- Falta un auto completador -->
        </div>
        <p class="help">{{ help }}</p>
        <div>
            <p class="message">{{ errorMessage }}</p>
        </div>
        <div class="messages" v-for="message in errorMessages" :key="message">
            <p class="message">{{ message }}</p>
        </div>
    </div>
</template>


<style scoped lang="scss">
@import '../../assets/scss/_variable';
* {
    box-sizing: border-box;
}

.outer {
    padding-bottom: 1rem;
}

.outer.disabled .input {
    background-color: #515e74;
}

.error.input {
    box-shadow: 0 0 10px 2px rgba(255, 8, 0, 0.24);
}

.succes.input {
    box-shadow: 0 0 10px 2px rgba(0, 230, 69, 0.473);
}

.label {
    font-weight: 500;
    font-size: 1.3rem;
}

.help,
.message {
    font-size: 0.85rem;
    margin: 5px 0;
}

.message {
    color: $redSec;
    font-weight:bold;
    // padding:10px;
    // background-color:$redSec-300;
}

/* General <<<<<<<<<<<<<<<<< */

.inner {
    position: relative;
}

.dark .input,
.option {
    padding: 8px;
    outline: none;
    border: none;
    background: $dark-darkocean;
    transition: .4s;
    width: 100%;
    border: solid .5px #000;
    border-radius: 5px !important;
    //color: aliceblue;
}
.input,
.option {
    padding: 8px;
    outline: none;
    border: none;
    background: $darkocean;
    transition: .4s;
    width: 100%;
    border: solid .5px $bordercolor;
    border-radius: 5px;
    //color: aliceblue;
}

.dark .input:focus,
.option:hover,
.option.active
{
    // background: $dark-darkocean;
    border: solid .5px $primary;
    cursor: pointer;
}
.input:focus,
.option:hover,
.option.active
{
    // background: $dark-darkocean;
    border: solid .5px $primary;
    cursor: pointer;
}

.input::placeholder {
    color: dimgrey;
}

.input:disabled {
    cursor: default;
}

.select {
    position: relative;
    width: 100%;
}

.inputSelect {
    caret-color: transparent;
    cursor: pointer;
}

.inputSelect:focus {
    border-radius: 11px 11px 0 0;
}

.options {
    position: absolute;
    display: none;
    width: 100%;
    opacity: 0;
    transition: opacity .4s;
    z-index: 1;
    background-color: rgb(247, 247, 247);
}

.inputSelect:focus+.options {
    display: block;
    opacity: 1;
}

.options:active {
    display: block;
}

.option {
    border-radius: 0;
    border-color: rgb(218, 218, 218);
    background-color: rgb(247, 247, 247);

}
.dark .option {
    border-radius: 0;
    border-color: rgb(53, 53, 53);
    background-color: rgb(31, 31, 31);

}

.option:last-of-type {
    border-radius: 0 0 11px 11px;
}
</style>